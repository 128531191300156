import React from "react";
import { Container, Row, Col } from "reactstrap";
import { skillsData } from "../../libs/skillsData"; 
import { certData } from "../../libs/certData";



function SectionSkills() {
  return (
    
    <div className="section section-dark" id="skills">
      <Container>
      <div className="section-description text-center">
                <h2 className="title">
                  Skills & Certification&nbsp;
                  <i className="nc-icon nc-trophy"></i>
                  </h2>
        </div>
        <Row>
        <Col md="6">
            {Object.keys(skillsData).map((key) => (
        <div key={key}>
            <h3 className="skill-category-header">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
            <div className="skills-container">
                {skillsData[key].map((skill, index) => (
                    key === "certifications" ? 
                        <a key={index} href={certData[skill]} target="_blank" rel="noopener noreferrer" className="skill-item">
                            <strong>{skill}</strong>
                        </a> :
                        <div key={index} className="skill-item">
                            <strong>{skill}</strong>
                        </div>
                ))}
            </div>
        </div>
    ))}
</Col>
          <Col md="6">
          <img
                alt="..."
                className="skill-image"
                src={require("assets/img/portfolio-page/skills.png")}
              />
          </Col>
        </Row>
      </Container>
   
    </div>
  );
}

export default SectionSkills;
