import React from "react";

// reactstrap components
import { 
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col


} from "reactstrap";

function SectionExperience() {
  return (
    <>
      <div className="section section-sections section-dark" id="experience">
      <Container>
      <Row >
			<Col className="ml-auto mr-auto">
			  <div className="section-description text-center">
                <h2 className="title">
                  Experience &nbsp;
                  <i className="nc-icon nc-briefcase-24"></i>
                  </h2>
                  
                <br />
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="4">
                      <div className="card-image">
                          <a href="https://www.fujifilm.com/fbsg/en" target="_blank" rel="noopener noreferrer">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/portfolio-page/logo/fujilogo.png")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col md="8">
                      <CardBody>
                        <h4 className="card-category text-info"><strong>FujiFilm Business Innvoation Singapore</strong></h4>
                        <CardTitle tag="h3">
                        Technical Consultant
                        </CardTitle>
                        <p className="description">
                          <strong>Oct 2022 - Present</strong>
                        </p>
                        <p className="card-description">
                         Responsible for the entire SDLC, which includes designing, building, testing, deploying, and supporting applications. 
                         Managing Azure Cloud Services and Cloud Security. Equipped to provide the best experience to my clients.
                        </p>
                        <p className="description">
                         Tech Stack - (HTML,CSS,JS,Jquery), C# .NET (EF, Blazor), MS SQL, Microservices, Azure Cloud, Azure DevOps (CI/CD), Git
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                <Row className="flex-column-reverse flex-md-row">
                    <Col md="8">
                      <CardBody>
                        <h4 className="card-category text-danger">
                          <strong>Avanade Singapore</strong>
                        </h4>
                        <CardTitle tag="h3">
                        Analyst, Software Engineer
                        </CardTitle>
                        <p className="description">
                          <strong>Apr 2022 - Sept 2022</strong>
                        </p>
                        <p className="card-description">
                         Encompassed the enhancement and maintenance of applications. 
                         Effectively implemented updates and improvements to optimize application performance and user experience.
                        </p>
                        <p className="description">
                        Tech Stack - (HTML,CSS,JS,Jquery), C# .NET (EF, Blazor)
                        </p>
                      </CardBody>
                    </Col>
                    <Col md="4">
                      <div className="card-image">
                      <a href="https://www.avanade.com/zh-sg" target="_blank" rel="noopener noreferrer">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/portfolio-page/logo/avanadelogo.png")}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="4">
                      <div className="card-image">
                          <a href="https://scale.nus.edu.sg/" target="_blank" rel="noopener noreferrer">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/portfolio-page/logo/nuslogo.png")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col md="8">
                      <CardBody>
                        <h4 className="card-category text-info"><strong>National University Of Singapore (SCALE)</strong></h4>
                        <CardTitle tag="h3">
                        System Analyst
                        </CardTitle>
                        <p className="description">
                          <strong>Oct 2020 - Apr 2022</strong>
                        </p>
                        <p className="card-description">
                        Function in a dual capacity as a Software Engineer and a Project Manager. 
                        Provide the best user experience to stakeholders.
                        Parallelly, led a software project ensuring that deliverables were met within designated timelines and according to set quality standards.
                        </p>
                        <p className="description">
                        Tech Stack - (HTML,CSS,JS,Jquery), C# .NET (EF, Blazor)
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog" style={{marginBottom: '100px'}}>
                <Row className="flex-column-reverse flex-md-row">
                    <Col md="8">
                      <CardBody>
                        <h4 className="card-category text-danger">
                          <strong>Toppan Security Printing Pte. Ltd.</strong>
                        </h4>
                        <CardTitle tag="h3">
                        Software Engineer
                        </CardTitle>
                        <p className="description">
                          <strong>June 2020 - Oct 2020</strong>
                        </p>
                        <p className="card-description">
                        Encompassed enhancement and maintenance of applications to optimize, <br/> and maintain robust and user-friendly applications.
                        </p>
                        <p className="description">
                        Tech Stack - (HTML,CSS,JS,Jquery), C# .NET (EF, Blazor)
                        </p>
                      </CardBody>
                    </Col>
                    <Col md="4">
                      <div className="card-image">
                        <a href="https://www.toppanleefung.com/companies/toppan-security-system-pte-ltd/" target="_blank" rel="noopener noreferrer">
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/portfolio-page/logo/topanlogo.png")}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Card>

                
				</div>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  );
}

export default SectionExperience;
