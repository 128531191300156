import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendEmail } from "../../actions/sendEmail";
import toast, { Toaster } from 'react-hot-toast';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

function SectionConnectWithMe() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("senderEmail", email);
    formData.append("message", message);

    setIsLoading(true); 

    const response = await sendEmail(formData);
    
    setIsLoading(false); 

    if (response.error) {
        toast.error("Email was not sent out. Something went wrong!");
    } else {
        setEmail("");
        setMessage("");
        toast.success('Email sent successfully.');
    }
};

  return (
    <>
      <div className="section section-examples section-seasalt" id="contactme">
        <Container >
          <Row>
            <div className="title text-center">
              <h2 className="title">
                <strong>Connect With me</strong> &nbsp;
                <i className="nc-icon nc-email-85"></i>

                </h2>
              <h5 className="card-description">
              <strong>
                You can also contact me directly @ &nbsp;
                <a href="mailto:Joshuachanjw94@gmail.com" style={{textDecoration: 'underline', fontWeight: 'bold'}}>
                  Joshuachanjw94@gmail.com
                </a>
               
              </strong>
            </h5>
            </div>
          </Row>
          <Row>
              <Col className="ml-auto mr-auto" md="9">
                    <Col className="ml-auto mr-auto" md="12">
                    <Form id="contact-form" method="post" role="form" onSubmit={handleSubmit}>
                    <FormGroup className="label-floating">
                      <label className="control-label">
                        <h5><strong>Email Address</strong></h5>
                      </label>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className="label-floating">
                      <label className="control-label">
                        <h5><strong>Message</strong></h5>
                      </label>
                      <Input
                        id="message"
                        name="message"
                        placeholder="Your Message"
                        type="textarea"
                        rows="6"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />
                    </FormGroup>
                    <Row>
                      <Col md="12">
                      <Button
                          className="pull-right"
                          color="info"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <Spinner size="sm" color="secondary" /> : 'Send Message'}
                          &nbsp; &nbsp;
                          <i className="nc-icon nc-send"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                    </Col>
              </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionConnectWithMe;
