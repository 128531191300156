/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

function PortfolioFooter() {
  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/joshuachanjw"
                    target="_blank"
                    className="mr-1"
                  >
                    <span style={{ color: 'white' }}>LinkedIn</span>
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright" style={{ color: 'white' }}>
                © {new Date().getFullYear()}
                , Joshua Chan
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default PortfolioFooter;
