import React from "react";
import { validateString, getErrorMessage } from "../libs/utils";

export const sendEmail = async (formData) => {
  const senderEmail = formData.get("senderEmail");
  const message = formData.get("message");

  // simple client-side validation (remains the same)
  if (!validateString(senderEmail, 500)) {
    return {
      error: "Invalid sender email",
    };
  }
  if (!validateString(message, 5000)) {
    return {
      error: "Invalid message",
    };
  }

  try {
    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        senderEmail: senderEmail,
        message: message,
      }),
   });
    const data = await response.json();
    return data;

  } catch (error) {
    return {
      error: getErrorMessage(error),
    };
  }
};
