import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionAboutMe() {
  return (
    <>
      <div className="section section-components section-dark"  id="aboutme">
        <Row className="align-items-center">
          <Col lg="6" md="12">
            <div className="image-container">
            <img
                alt="..."
                className="components-anime"
                src={require("assets/img/anime.png")}
                
              />
            </div>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container">
              <h3 className="title">About Me &nbsp;               
              <i className="nc-icon nc-bulb-63"></i>
              </h3>
              <h5 className="description">
              I am a passionate Software Engineer with three years of hands-on experience primarily in the .NET ecosystem. 
              I take pride in developing cutting-edge applications using .C# .NET
              <br/>               <br/>
              Throughout my career, 
              I've been part of several innovative projects, where I've demonstrated proficiency in crafting seamless user interfaces on 
              interactive websites and web applications to provide pratical solutions to my clients.
              <br/>           <br/>
              I thrive in dynamic environments, adapting quickly and solving challenges with enthusiasm and determination.
              As a Software Engineer, I'm committed to continuous growth, ensuring I stay up-to-date with the ever-evolving technology landscape.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionAboutMe;
