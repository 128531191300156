import React from "react";

// Main Components
import PortfolioNavbar from "components/Navbar/PortfolioNavbar.js";
import PortfolioHeader from "components/Header/PortfolioHeader.js";
import PortfolioFooter from "components/Footer/PortfolioFooter.js";

//Portfolio Content Sections
import SectionSkills from "./portfolio-sections/SectionSkills.js";
import SectionAboutMe from "./portfolio-sections/SectionAboutMe.js";
import SectionProjects from "./portfolio-sections/SectionProjects.js";
import SectionExperience from "./portfolio-sections/SectionExperience.js";
import SectionConnectWithMe from "./portfolio-sections/SectionConnectWithMe.js";
import ScrollToTopButton from "./portfolio-sections/ScrollToTopButton.js";

// Alert
import toast, { Toaster } from 'react-hot-toast';


function Portfolio() {

  document.documentElement.classList.remove("nav-open");
  const checkScroll = () => {

    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
   
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
     
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };

  React.useEffect(() => {
    document.body.classList.add("portfolio-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("portfolio-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
      
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      
      />
      <PortfolioNavbar />
      <PortfolioHeader />
      <SectionAboutMe />
      <SectionProjects />
      <SectionSkills />
      <SectionExperience />
      <SectionConnectWithMe />
      <PortfolioFooter />
      <ScrollToTopButton />
    </>
  );
}

export default Portfolio;
