import React from "react";
import { Link } from "react-router-dom";
import animeImage from 'assets/img/anime.png';

// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components
function handleBrandClick(e) {
  e.preventDefault();
  const headerElement = document.getElementById('topheader');
  if (headerElement) {
      headerElement.scrollIntoView({ behavior: 'smooth' });
  }
}
function PortfolioNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    // Function to handle scroll
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0.1; 
      setNavbarColor(isScrolled ? '' : 'navbar-transparent');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
     {bodyClick ? (
      <div
          id="bodyClick"
          onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setBodyClick(false);
              setCollapseOpen(false);
          }}
      />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor, "navbar-smaller-buttons")}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
          <NavbarBrand id="navbar-brand" onClick={handleBrandClick}>
              {navbarColor !== "navbar-transparent" ? (
                  <span style={{ color: 'black' }} className="cursor-pointer">Joshua</span>
                  ) : (
                  <span style={{ color: 'white' }}  className="cursor-pointer">Joshua</span>
              )}
          </NavbarBrand>
          <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setBodyClick(true);
                  setCollapseOpen(!collapseOpen);
              }}
          >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
          </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
            <Button
              className={classnames("btn-neutral", {
                "text-black": navbarColor !== "navbar-transparent"
              })}              
              color="link"
              href="#aboutme"
              style={{ fontSize: '18px' }} 
            >
              About Me
             </Button>
             <Button
              className={classnames("btn-neutral", {
                "text-black": navbarColor !== "navbar-transparent"
              })}   
              color="link"
              href="#projects"
              style={{ fontSize: '18px' }} 
            >
              Projects
             </Button>
             <Button
              className={classnames("btn-neutral", {
                "text-black": navbarColor !== "navbar-transparent"
              })}                 
              color="link"
              href="#skills"
              style={{ fontSize: '18px' }} 
            >
              Skills
             </Button>
             <Button
          className={classnames("btn-neutral", {
            "text-black": navbarColor !== "navbar-transparent"
          })}                 
            color="link"
              href="#experience"
              style={{ fontSize: '18px' }} 
            >
              Experience
             </Button>
             <Button
              className={classnames("btn-neutral", {
                "text-black": navbarColor !== "navbar-transparent"
              })}   
              color="link"
              href="#contactme"
              style={{ fontSize: '18px' }} 
            >
              contact
             </Button>
            </Nav>
            </Collapse>

        </Container>
      </Navbar>
    </>
  );
}

export default PortfolioNavbar;
