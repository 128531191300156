import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Alert 

} from "reactstrap";

// core components

function SectionProjects() {
  const [isHovered, setIsHovered] = useState(false);

  const normalImage = require("assets/img/portfolio-page/HRManagementSystem.png");
  const hoverImage = require("assets/img/portfolio-page/HRManagementSystemDashboard.png"); //


  return (
    <>
      <div id="projects" >
      <div className="project-4 section section-seasalt" >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Project Space 
                  <i className="nc-icon nc-air-baloon"></i>
                  </h2>
                  <h5>
                    <strong>"Beyond the syntax, there's a story."</strong>
                  </h5>
                </Col>
              </Row>
              <div className="space-top" />
              <Row>
                <Col className="ml-auto" md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage: `url(${isHovered ? hoverImage : normalImage})`,
                  }}
                  onMouseEnter={() => setIsHovered(true)} 
                  onMouseLeave={() => setIsHovered(false)}
                >
                </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card className="card-plain">
                    <CardBody>
                      <h6 className="card-category">Web Application</h6>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                          <strong>HR Management System</strong>
                        </CardTitle>
                      </a>
                      <p className="card-description">
                      The HR Management System is an innovative platform designed to streamline and automate human resource processes for modern organizations.
                      <br/> <br/>
                      Built using React with the robust Core UI framework, it offers an intuitive and responsive user interface. 
                      The backend is powered by C# .NET ensuring seamless integration capabilities.
                      Microservice for authentication, ensuring security, scalability.
                      </p>
                      <CardFooter style={{marginBottom: '200px'}}>
                        {/* <Button
                          className="mr-1"
                          color="default"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}

                        >
                          <i className="fa fa-book mr-1" />
                          Read More
                        </Button>
                        <Button
                            className=" mr-1"
                            color="info"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                        >
                            <i className="fa fa-github mr-1" />
                            Repository
                        </Button>

                        <Button color="success">
                        <i className="fa fa-globe mr-1" />
                          Demo
                          </Button> */}
                        <Alert color="danger">
                          <strong>Working In Progress...</strong>
                          <br/> 
                          Demo & Repo coming soon!
                          <br/>
                          (Contact me to demo on local)
                        </Alert>
                       
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
    
            </Container>
          </div>
        </div>
    </>
  );
}

export default SectionProjects;
